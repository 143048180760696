<template>
  <div>
    <a-modal v-model:visible="visible" title="新增药名" @ok="handleOk" okText="新增" cancelText="关闭">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">

        <a-form-item label="药品名称">
          <a-input v-model:value="data.name" @change="changeNameInitial()" />
        </a-form-item>
        <a-form-item label="首字母">
          <a-input v-model:value="data.nameInitial" />
        </a-form-item>
        <a-form-item label="是否别称">
          <a-radio-group v-model:value="data.isAlias" option-type="button" :options="[{ label: '是', value: true }, { label: '否', value: false }]" />
        </a-form-item>
      </a-form>

    </a-modal>
  </div>
</template>

<script>
import { Add } from "/src/api/medicine.js";
import { pinyin } from 'pinyin-pro';
import medicineSelector from '/src/components/selector/medicineSelector.vue';
export default {
  components: {
    medicineSelector
  },
  data() {
    return {
      visible: this.modelValue,
      medicineFilter: "",
      columns: [
        {
          title: "名称",
          key: "itemName",
          dataIndex: "itemName",
          width: "30%",
          slots: {
            customRender: "itemName"
          }
        },
        {
          title: "用量",
          key: "quantity",
          dataIndex: "quantity",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "quantity"
          }
        },
        {
          title: "描述",
          key: "description",
          dataIndex: "description",
          // ellipsis: true,
          width: "35%",
          slots: {
            customRender: "description"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "15%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      medicine: "",
      data: {
        name: undefined,
        nameInitial: undefined,
        isAlias: false,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {

  },
  methods: {
    filterNameInitial(input) {
      this.medicineFilter = input?.toLowerCase();
    },
    filter() {
      return true
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      if (res.errCode == 0) {
        this.data = this.data.filter(function (item) {
          return item.id !== id;
        });
      } else {
        this.$message.error("删除失败", function () { });
      }

    },

    async handleOk() {
      let _this = this;
      let obj = { ...this.data }
      let res = await Add(obj)
      if (res.errCode == 0) {
        _this.$message.success("新建成功", function () { });
        _this.visible = false
        this.data = {
          name: undefined,
          description: undefined,
          source: undefined,
          items: [],
        }
        this.$store.commit('getPrescriptionList')
        this.$emit('data', res.data);
      }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    },
    getInitial(name) {
      let list = name.split("");
      let str = "";
      list.forEach(item => {
        str += pinyin(item, { toneType: 'none' }).split("")[0];
      });
      return str
    },
    changeNameInitial() {
      this.data.nameInitial = this.getInitial(this.data.name)
    },
    //初始化data所有数据，并关闭modal
  },
  watch: {
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
</style>
