<template>
  <div>
    <a-modal v-model:visible="visible" title="药品详情" okText="新建" cancelText="取消" :okButtonProps="{ style: { display: 'none' }}" :afterClose="closeModal" width="50vw">
      <div class="detailModal">
        <div><label>名称：</label><span>{{info?.name}}</span></div>
        <div><label>首字母：</label><span>{{info?.nameInitial}}</span></div>
        <div><label>描述：</label><span>{{info?.description}}</span></div>
      </div>
      <a-table class="ant-table-striped" :columns="columns" :data-source="data" rowKey="id" :pagination="false" bordered>
        <template #title>
          <div class="thead">别名列表</div>
        </template>

        <template #operation="{ record }">
          <div class="flex_sa">
            <div class="editable-row-operations flex_sa">
              <span v-if="editableData[record.id]">
                <a @click="save(record.id)">保存</a>
              </span>
              <span v-if="editableData[record.id]">
                <a @click="cancel(record.id)">取消</a>
              </span>
              <span v-else>
                <a @click="edit(record.id)">编辑</a>
              </span>
            </div>
            <div class="editable-row-operations">
              <span>
                <a-popconfirm title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>

          </div>

        </template>

        <template v-for="col in ['alias']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" style="margin: -5px 0" @change="changeNameInitial(record.id)" />
            <template v-else>{{ text }}</template>
          </div>
          <!-- <div>
              <a-input-number v-model:value="record[col]" style="margin: -5px 0" />
            </div> -->
        </template>
        <template v-for="col in ['aliasInitial']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" style="margin: -5px 0" />
            <template v-else>{{ text }}</template>
          </div>
          <!-- <div>
              <a-input-number v-model:value="record[col]" style="margin: -5px 0" />
            </div> -->
        </template>

      </a-table>
      <div style="text-align: center;margin-top: 20px;">
        <a-button type="primary" @click="addToTable">添加别名</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import { pinyin } from 'pinyin-pro';
import { Detail } from "/src/api/medicine.js";
import { Add, Update, Delete } from "/src/api/medicineAlias.js";
export default {
  data() {
    return {
      visible: this.modelValue,
      columns: [
        {
          title: "名称",
          key: "alias",
          dataIndex: "alias",
          width: "30%",
          slots: {
            customRender: "alias",
          }
        },
        {
          title: "首字母",
          key: "aliasInitial",
          dataIndex: "aliasInitial",
          // ellipsis: true,
          width: "20%",
          slots: {
            customRender: "aliasInitial",
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      info: {},
      data: [],
      editableData: reactive({}),
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {

  },
  activated() {

  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {}
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    
  },
  methods: {
    async detail() {
      console.log(this.record.uuid);
      let res = await Detail(this.record.uuid)
      if (res.errCode == 0) {
        console.log(res);
        this.info = res.data
        this.data = res.data?.aliases
      }
    },
    async onDelete(id) {
      this.editableData = {}
      let item = this.data.find(item => item.id == id)
      let flag = item.name || item.aliasInitial
      if (flag) {
        let res = await Delete({ id })
        if (res.errCode == 0) {
          this.data = this.data.filter(function (item) {
            return item.id !== id;
          });
        }
      } else {
        console.log("没有初始数据，直接删除");
        this.data = this.data.filter(function (item) {
          return item.id !== id;
        });
      }
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;

      if (!this.editableData[id].alias || !this.editableData[id].aliasInitial) {
        this.$message.info("请输入完整信息", function () { });
        return
      }
      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      let res;
      let params = { ...this.editableData[id], name: this.info.name }
      if (params.type == 'new') {
        delete params.id
        console.log(params);
        res = await Add(params)
      } else {
        res = await Update(params)
      }
      if (res.errCode == 0) {
        this.detail()
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
      let flag = this.data.find(item => item.id == id)?.quantity
      if (!flag) {
        this.data = this.data.filter(item => item.id != id)
      }
    },
    closeModal() {
      this.editableData = {}
      this.$emit('update:modelValue', false);
    },
    addToTable() {
      //判断对象是否为空
      if (Object.keys(this.editableData).length) {
        this.$message.info("请先完成当前操作", function () { });
        return
      }
      let id = Date.now() + Math.floor(Math.random() * 1000);
      let obj = { id, alias: "", aliasInitial: "", type: "new" }
      this.data.push({ ...obj })
      this.editableData[id] = { ...obj }
    },
    getInitial(name) {
      let list = name.split("");
      let str = "";
      list.forEach(item => {
        str += pinyin(item, { toneType: 'none' }).split("")[0];
      });
      return str
    },
    changeNameInitial(id) {
      this.editableData[id].aliasInitial = this.getInitial(this.editableData[id].alias)
    },


  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.thead {
  text-align: center;
  font-weight: bold;
  letter-spacing: 1em;
}
</style>
