import { request } from "/src/utils/index.js";

export function Search(params) {
  return request.get("tcm/medicine/alias/list", { params });
}
export function Detail(params) {
  return request.get("tcm/medicine/alias" + params);
}

export function Add(params) {
  return request.post("tcm/medicine/alias", params);
}

export function Update(params) {
  return request.put("tcm/medicine/alias", params);
}

export function Delete(data) {
  return request.delete("tcm/medicine/alias", { data });
}
