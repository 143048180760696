<template>
  <div>
    <div>
      <div class="searchArea">
        <!-- <a-range-picker v-model:value="inputs.patroltime" :placeholder="['应巡开始时间','应巡结束时间']" :locale="locale" /> -->
        <a-input v-model:value="inputs.name" placeholder="药名" />
        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
        <a-button style="margin-left: 10px" type="primary" @click="visible2=true">
          <template #icon>
            <PlusOutlined />
          </template>
          新增药品
        </a-button>
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="data" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered rowKey="name" :pagination="{ pageSize:$store.state.tableSize ,total,current}" @change="search" >

        <template #operation="{ record }">
          <div class="flex_sa">
            <div class="editable-row-operations">
              <span>
                <a class="table_btn" @click="getMedicine(record)">别名管理</a>
              </span>
            </div>
            <div class="editable-row-operations flex_sa" v-if="!record.isAlias" style="width: 100px;">
              <span v-if="editableData[record.id]">
                <a @click="save(record.id)">保存</a>
              </span>
              <span v-if="editableData[record.id]">
                <a @click="cancel(record.id)">取消</a>
              </span>
              <span v-else>
                <a @click="edit(record.id)">编辑</a>
              </span>
            </div>
            <div class="editable-row-operations" v-if="!record.isAlias">
              <span>
                <a-popconfirm v-if="data.length" title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>

          </div>

        </template>

        <template v-for="col in ['nameInitial']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" style="margin: -5px 0" />
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template v-for="col in ['isAlias']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- {{ text?"是":"否" }} -->
            <check-outlined v-if="text" style="color:green" />
            <close-outlined v-else style="color:red" />
          </div>
        </template>
        <template v-for="col in ['name']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" @change="changeDataNameInitial(record)" style="margin: -5px 0" />
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template v-for="col in ['gender']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <!-- <a-input v-model:value="editableData[record.id][col]" /> -->
            <a-select v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" placeholder="性别" allowClear style="width:80px;text-align: center;">
              <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
              <a-select-option value="男">男</a-select-option>
              <a-select-option value="女">女</a-select-option>
            </a-select>
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template v-for="col in ['isStandard']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <div v-if="text">
              <check-circle-filled style="color:#389e0d;font-size:20px" />
            </div>
            <div v-else>
              <warning-filled style="color:#d4380d;font-size:20px" />
            </div>
          </div>
        </template>
        <template v-for="col in ['standardName']" #[col]="{ text, record }" :key="col">
          <div v-if="record.isAlias">
            <div>{{text}}</div>
          </div>
        </template>
      </a-table>

    </div>
    <!-- 详情 -->
    <getMedicine v-model="visible" :record="selectedRow" />
    <!-- 新增 -->
    <addMedicine v-model="visible2" />

  </div>
</template>


<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}
.offline {
  background: #979596;
  border-color: #979596;
}

:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}
.table_btn {
  width: 70px;
  display: inline-block;
  text-align: center;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
  DownloadOutlined,
  CheckCircleFilled,
  WarningFilled,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Search, Update, Delete } from "/src/api/medicine.js";
import getMedicine from '/src/components/medicine/getMedicine.vue';
import addMedicine from '/src/components/medicine/addMedicine.vue';

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    DownloadOutlined,
    locale,
    CheckCircleFilled,
    WarningFilled,
    getMedicine,
    addMedicine,
    CheckOutlined,
    CloseOutlined,
  },
  name: "药名管理",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      visible: false,
      visible2: false,
      active: undefined,
      id: undefined,
      total: 0,
      current: 1,
      // pageSize: 10,
      inputs: {
        name: undefined,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns: [
        {
          title: "药品",
          key: "name",
          dataIndex: "name",
          width: "25%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "首字母",
          key: "nameInitial",
          dataIndex: "nameInitial",
          ellipsis: true,
          width: "15%",
          slots: {
            customRender: "nameInitial"
          },
        },
        {
          title: "标准名称",
          key: "standardName",
          dataIndex: "standardName",
          ellipsis: true,
          width: "15%",
          slots: {
            customRender: "standardName"
          },
        },
        {
          title: "是否别名",
          width: "15%",
          key: "isAlias",
          dataIndex: "isAlias",
          slots: {
            customRender: "isAlias"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "15%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      loading: true,
      editableData: reactive({}),
      selectedRow: "",
      selectedRows: {}
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.search("");
  },
  activated() {

  },
  methods: {
    resetForm() {
      this.inputs = {
        name: undefined,
      }
    },
    getMedicine(record) {
      this.visible = true
      this.selectedRow = { ...record }
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      this.data = this.data.filter(function (item) {
        return item.id !== id;
      });
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;

      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      let params = {
        id: this.editableData[id].id,
        name: this.editableData[id].name,
        nameInitial: this.editableData[id].nameInitial,
        gender: this.editableData[id].gender,
      }
      let res = await Update(params)
      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
    },
    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      let _this = this;
      this.editableData = {}
      let params = {
        name: this.inputs.name,
        isStandard: this.inputs.isStandard == undefined ? "" : this.inputs.isStandard,
        pageSize: this.$store.state.tableSize
      };
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current
        this.current = pagination.current;
      }
      let res = await Search(params)
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.total = res.data.total;
        this.data = res.data.records;
      } else {
        this.$message.error("查询失败", function () { });
      }
      // this.data = res
    },

  }
};
</script>